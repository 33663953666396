@keyframes fadeIn {
  from {
    filter: blur(5px);
    opacity: 0;
  }

  to {
    filter: blur(0);
    opacity: 1;
  }
}

.animation-fade-in {
  animation: 0.8s fadeIn ease;
}
