#modal {
  position: relative;
  z-index: 999;
}

.banner-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);
}

.banner-overlay .banner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.banner-container .banner-image {
  position: relative;
}

.banner-container .banner-image img {
  opacity: 0;
  margin: 0 auto;
  max-height: calc(100vh - 40px);
  max-width: 100%;
  width: auto;
  cursor: pointer;
  animation-name: bounce;
  animation-duration: 0.8s;
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes bounce {
  0% {
    transform: translateY(-100%);
  }
  10% {
    transform: translateY(-85%);
  }
  20% {
    transform: translateY(-70%);
  }
  30% {
    transform: translateY(-55%);
  }
  40% {
    transform: translateY(-40%);
  }
  50% {
    transform: translateY(-25%);
  }
  60% {
    transform: translateY(-10%);
  }
  70% {
    transform: translateY(5%);
  }
  80% {
    transform: translateY(10%);
  }
  90% {
    transform: translateY(3%);
  }
  100% {
    opacity: 1;
  }
}

.banner-container .banner-image > .hide-button {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 9999;
  background-color: rgba(52,58,64,.4);
  border-radius: 25px;
  color: white;
}