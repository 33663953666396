html,
body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
}

.splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.splash-screen > div {
  width: 500px;
  max-width: 90%;
}
