.sidebar {
  width: 100%;
}

.sidebar .mdc-list > li {
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 1200px) {
  .sidebar {
    max-width: 350px;
  }
}

.category-menu {
  width: 98%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .sidebar .sidebar-menu {
    top: 180px;
  }
}

@media (max-width: 693px) {
  .sidebar .sidebar-menu {
    top: 260px;
  }
}
